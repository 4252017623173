import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getDraftGallery } from '@wix/ambassador-fastgallery-draftgallery-v1-draft-gallery/http';
import { queryGalleries } from '@wix/ambassador-fastgallery-gallery-v1-gallery/http';
import { queryItems } from '@wix/ambassador-fastgallery-item-v1-item/http';
import {
  queryDraftItems,
  bulkCreateDraftItems,
  bulkDeleteDraftItems,
  updateDraftItem,
  moveDraftItems,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/http';
import {
  BulkCreateDraftItemsResponse,
  DraftItem,
  Sorting,
  SortOrder,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import { createFastGalleryApp } from '@wix/ambassador-fastgallery-app-v1-fast-gallery-app/http';
import { ViewMode } from '../types/viewMode';
import { Gallery } from '@wix/ambassador-fastgallery-gallery-v1-gallery/types';

const ITEMS_LIMIT_DEFAULT = 25;

interface IFetcherParams {
  httpClient: IHttpClient;
  viewMode: ViewMode;
  draftGalleryId: string;
}
export class FastGalleryService {
  private httpClient: IHttpClient;
  private viewMode: ViewMode;
  private draftGalleryId: string;
  constructor(props: IFetcherParams) {
    const { httpClient, viewMode, draftGalleryId } = props;
    this.httpClient = httpClient;
    this.viewMode = viewMode;
    this.draftGalleryId = draftGalleryId;
  }

  static async createGalleryInstance(
    httpClient: IHttpClient,
    originGalleryId?: string,
  ) {
    try {
      const { data } = await httpClient.request(
        createFastGalleryApp({ originDraftGalleryId: originGalleryId }),
      );
      return data.createdDraftGalleryId;
    } catch (err) {
      console.error('could not create gallery instance ', err);
    }
  }

  async getGalleryItemsInCurrentContext(limit: number = ITEMS_LIMIT_DEFAULT) {
    try {
      if (this.viewMode !== 'SITE') {
        return await this.getDraftGalleryItems(limit);
      } else {
        const publishedGalleryData = await this.getPublishedGalleryData();
        const publishedGalleryId = publishedGalleryData?.id || '';
        return await this.getPublishedGalleryItems(publishedGalleryId, limit);
      }
    } catch (err) {
      console.error('could not fetch gallery items ', err);
    }
  }

  async getGalleryDataInCurrentContext() {
    try {
      if (this.viewMode !== 'SITE') {
        return await this.getDraftGalleryData();
      } else {
        return await this.getPublishedGalleryData();
      }
    } catch (err) {
      console.error('could not fetch gallery data ', err);
    }
  }

  async getDraftGalleryItems(limit: number = ITEMS_LIMIT_DEFAULT) {
    try {
      const { data } = await this.httpClient.request(
        queryDraftItems({
          query: {
            filter: {
              draftGalleryId: this.draftGalleryId,
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
            cursorPaging: {
              limit,
            },
          },
        }),
      );
      return data.draftItems;
    } catch (err) {
      console.error('could not fetch gallery draft items ', err);
    }
  }

  async getAllDraftGalleryItems() {
    const draftGalleryItems: DraftItem[] = [];
    let hasNext = false;
    let cursor: string | null = null;
    let filter: Record<string, any> | undefined = {
      draftGalleryId: this.draftGalleryId,
    };
    let sort: Sorting[] | undefined = [
      { fieldName: 'sortOrder', order: SortOrder.ASC },
    ];
    do {
      try {
        const res = await this.httpClient.request(
          queryDraftItems({
            query: {
              filter,
              sort,
              cursorPaging: {
                limit: 100,
                cursor,
              },
            },
          }),
        );
        const { draftItems, pagingMetadata } = res.data;
        cursor = pagingMetadata?.cursors?.next || null;
        hasNext = (pagingMetadata?.hasNext as boolean) || false;
        filter = undefined;
        sort = undefined;
        draftItems && draftGalleryItems.push(...draftItems);
      } catch (error) {
        throw new Error('Failed to fetch draft items');
      }
    } while (hasNext);

    return draftGalleryItems;
  }

  async getPublishedGalleryItems(
    publishedGalleryId: string,
    limit: number = ITEMS_LIMIT_DEFAULT,
  ) {
    try {
      const { data } = await this.httpClient.request(
        queryItems({
          query: {
            filter: {
              galleryId: publishedGalleryId,
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
            cursorPaging: {
              limit,
            },
          },
        }),
      );
      return data.items;
    } catch (err) {
      console.error('could not fetch gallery published items ', err);
    }
  }

  async getDraftGalleryData() {
    try {
      const { data } = await this.httpClient.request(
        getDraftGallery({
          draftGalleryId: this.draftGalleryId,
        }),
      );
      return data.draftGallery;
    } catch (err) {
      console.error('could not fetch gallery draft gallery data ', err);
    }
  }

  async getPublishedGalleryData(): Promise<Gallery | void> {
    try {
      const { data } = await this.httpClient.request(
        queryGalleries({
          query: {
            filter: {
              draftGalleryId: { $eq: this.draftGalleryId },
            },
          },
        }),
      );
      return data?.galleries?.at(0);
    } catch (err) {
      console.error('could not fetch gallery published data ', err);
    }
  }

  async createDraftItemsInServer(
    draftItems: DraftItem[],
  ): Promise<DraftItem[] | undefined> {
    try {
      const response =
        await this.httpClient.request<BulkCreateDraftItemsResponse>(
          bulkCreateDraftItems({
            draftItems,
            returnEntity: true,
          }),
        );
      const createdItems = response.data.results?.flatMap(
        (result) => result.item || [],
      );
      return createdItems;
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }

  async deleteDraftItemsInServer(draftItemsIds: string[]): Promise<void> {
    try {
      await this.httpClient.request(
        bulkDeleteDraftItems({
          draftItemIds: draftItemsIds,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }

  async updateSingleDraftItemDataInServer(draftItem: DraftItem): Promise<void> {
    try {
      await this.httpClient.request(
        updateDraftItem({
          draftItem,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }

  async updateItemSortOrderInServer(
    itemIds: string[],
    afterItemId?: string,
  ): Promise<void> {
    try {
      await this.httpClient.request(
        moveDraftItems({
          itemIds,
          afterItemId,
        }),
      );
    } catch (err) {
      console.error('Failed to create draft items', err);
    }
  }
}
