import { Item } from '@wix/ambassador-fastgallery-item-v1-item/types';
import {
  getDraftItemLinkFieldOptions,
  getOrganizeMediaLinkType,
  defaultLinkText,
} from '../components/fastGalleryWidget/panels/helpers/organizeMediaGeneralUtils';
import {
  MediaManagerItem,
  OrganizeMediaItem,
  OrganizeMediaItemLink,
  OrganizeMediaItemPhoto,
} from '../types/organizedMediaTypes';
import {
  DraftItem,
  MediaItemType,
  Type as DraftItemType,
  Link,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import _ from 'lodash';

export const convertOrganizeMediaToDraftItemLink = (
  link?: OrganizeMediaItemLink,
): Link => {
  if (_.isEmpty(link)) {
    return {};
  }
  const linkData = link?.data;
  const draftItemLink = getDraftItemLinkFieldOptions(linkData);

  if (!draftItemLink) {
    return {};
  }

  return {
    target: linkData?.target || '_blank',

    wixLinkData: {
      [draftItemLink.type]: draftItemLink.wixLinkData,
    },
    ...('text' in link && { text: link.text }),
    type: draftItemLink.isInternal
      ? DraftItemType.INTERNAL
      : DraftItemType.EXTERNAL,
    ...(draftItemLink.url && { url: draftItemLink.url }),
  };
};

export const convertDraftItemLinkToOrganizeMedia = (
  link?: Link,
): OrganizeMediaItemLink | object => {
  if (!link?.wixLinkData) {
    return {};
  }
  const linkType = Object.keys(link.wixLinkData)[0];
  const data = {
    ...link.wixLinkData[linkType],
    type: getOrganizeMediaLinkType(linkType),
  };
  return {
    type: 'wix',
    text: defaultLinkText({ type: 'wix', data }),
    value: defaultLinkText({ type: 'wix', data }),
    data,
  };
};

export const convertMediaManagerItemsToDraftItems = (
  mediaArray: MediaManagerItem[],
  draftGalleryId: string,
): DraftItem[] => {
  return mediaArray.flatMap((item: MediaManagerItem) => {
    if (item.mediaType === 'picture') {
      return {
        draftGalleryId,
        title: item?.title,
        link: {},
        type: MediaItemType.IMAGE,
        media: {
          imageOptions: {
            focalPoint: {
              x: 0.5,
              y: 0.5,
            },
            imageInfo: {
              id: item.id || '',
              width: item.width,
              height: item.height,
              filename: item.fileName || '',
            },
          },
          type: MediaItemType.IMAGE,
        },
      } as DraftItem;
    } else {
      return [];
    }
  });
};

export const convertMediaManagerItemsOrganizeMediaItems = (
  mediaArray: MediaManagerItem[],
): OrganizeMediaItem[] => {
  return mediaArray.flatMap((item: MediaManagerItem) => {
    if (item.mediaType === 'picture') {
      const fileName = item.fileName;
      const width = item.width;
      const height = item.height;
      return {
        dataType: 'Photo',
        id: item.id,
        mediaUrl: fileName
          ? `https://static.wixstatic.com/media/${fileName}`
          : '',
        fileName,
        name: item.title,
        photoMetadata: {
          width,
          height,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        title: item.title,
        metaData: {
          width: item.width,
          height: item.height,
          fileName,
          name: item.title,
        },
        alt: '',
        link: {},
      } as OrganizeMediaItemPhoto;
    } else {
      return [];
    }
  });
};
export const convertDraftItemsToOrganizeMediaItems = (
  mediaArray: DraftItem[],
): OrganizeMediaItem[] => {
  return mediaArray.flatMap((item: DraftItem) => {
    if (item.media?.type === MediaItemType.IMAGE) {
      const fileName = item.media.imageOptions?.imageInfo?.filename;
      const width = item.media.imageOptions?.imageInfo?.width;
      const height = item.media.imageOptions?.imageInfo?.height;
      return {
        dataType: 'Photo',
        id: item.id,
        mediaUrl: fileName
          ? `https://static.wixstatic.com/media/${fileName}`
          : '',
        fileName,
        name: item.title,
        photoMetadata: {
          width,
          height,
          focalPoint: {
            x: item.media.imageOptions?.focalPoint?.x || 0.5,
            y: item.media.imageOptions?.focalPoint?.y || 0.5,
          },
        },
        title: item.title,
        metaData: {
          width,
          height,
          fileName,
          name: item.title,
        },
        alt: item.media.imageOptions?.imageInfo?.altText,
        link: convertDraftItemLinkToOrganizeMedia(item.link),
        orderIndex: item.sortOrder || -new Date()?.getTime(),
        revision: item.revision,
      } as OrganizeMediaItemPhoto;
    } else {
      return [];
    }
  });
};

export const convertOrganizeMediaItemsToDraftItems = (
  mediaArray: OrganizeMediaItem[],
  draftGalleryId: string,
): DraftItem[] => {
  return mediaArray.flatMap((item: OrganizeMediaItem) => {
    if (item.dataType === 'Photo') {
      return {
        id: item.id,
        draftGalleryId,
        title: item?.title,
        link: convertOrganizeMediaToDraftItemLink(item?.link),
        sortOrder: item.orderIndex,
        type: MediaItemType.IMAGE,
        media: {
          imageOptions: {
            secure: item.isSecure,
            focalPoint: {
              x: item?.photoMetadata?.focalPoint?.x || 0.5,
              y: item?.photoMetadata?.focalPoint?.y || 0.5,
            },
            imageInfo: {
              id: item.fileName || '',
              filename: item.fileName,
              width: item.photoMetadata?.width,
              height: item.photoMetadata?.height,
              altText: item?.alt,
              url: item.mediaUrl,
            },
          },
          type: MediaItemType.IMAGE,
        },
        revision: item?.revision,
      } as DraftItem;
    } else {
      return [];
    }
  });
};

export const convertDraftItemToWixCodeItem = (
  mediaArray: DraftItem[] | Item[] = [],
) => {
  return mediaArray.flatMap((item: DraftItem) => ({
    title: item?.title,
    description: '',
    alt: item?.media?.imageOptions?.imageInfo?.altText || '',
    src: `wix:image://v1/${item?.media?.imageOptions?.imageInfo?.filename}/${item?.media?.imageOptions?.imageInfo?.filename}#originWidth=${item?.media?.imageOptions?.imageInfo?.width}&originHeight=${item?.media?.imageOptions?.imageInfo?.height}`,
    type: item?.media?.type || 'image',
    settings: {
      focalPoint: [
        item?.media?.imageOptions?.focalPoint?.x,
        item?.media?.imageOptions?.focalPoint?.y,
      ],
    },
    link: {
      url: item?.link?.url || '',
      target: item?.link?.target || '',
    },
  }));
};
