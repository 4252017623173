import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { ExpandModeMainWidgetEvents as Events } from './config/constants';
import { expandModePluginProps } from '../../constants/generalConstants';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

export default createBlocksModel({
  widgetName: 'ExpandModeMainWidget',
  props: expandModePluginProps,
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    [Events.closeClicked]: {
      description: 'Fired when close button clicked',
    },
  },
});
