import {
  LINK_PANEL_LINK_TYPES,
  WIX_LINK_DATA_LINK_TYPE,
} from '../../../../constants/organizeMediaConstants';
import {
  OrganizeMediaItemLink,
  OrganizeMediaLinkPanelData,
} from '../../../../types/organizedMediaTypes';

export const defaultLinkText = (link: OrganizeMediaItemLink): string => {
  const linkData = link.data;
  // TODO: add translations for the return values
  switch (linkData.type) {
    case LINK_PANEL_LINK_TYPES.PAGE_LINK:
    case LINK_PANEL_LINK_TYPES.DYNAMIC_PAGE_LINK:
    case LINK_PANEL_LINK_TYPES.TPA_PAGE_LINK:
      return `Go to Page`;
    case LINK_PANEL_LINK_TYPES.ANCHOR_LINK:
      return `Scroll to ${linkData.anchorName}`;
    case LINK_PANEL_LINK_TYPES.EXTERNAL_LINK:
      return `${linkData.url}`;
    case LINK_PANEL_LINK_TYPES.EMAIL_LINK:
      return `Email ${linkData.recipient}`;
    case LINK_PANEL_LINK_TYPES.PHONE_LINK:
      return `Call ${linkData.phoneNumber}`;
    case LINK_PANEL_LINK_TYPES.DOCUMENT_LINK:
      return `Open ${linkData.name}`;
    default:
      return 'Go to Link';
  }
};

export const defaultLinkValue = (link: OrganizeMediaItemLink): string => {
  const linkData = link.data;

  switch (linkData.type) {
    case LINK_PANEL_LINK_TYPES.PAGE_LINK:
    case LINK_PANEL_LINK_TYPES.DYNAMIC_PAGE_LINK:
    case LINK_PANEL_LINK_TYPES.TPA_PAGE_LINK:
      return WIX_LINK_DATA_LINK_TYPE.PAGE;
    case LINK_PANEL_LINK_TYPES.ANCHOR_LINK:
      return `ANCHOR - ${linkData.anchorName}`;
    case 'ExternalLink':
      return `LINK - ${linkData.url}`;
    case LINK_PANEL_LINK_TYPES.EMAIL_LINK:
      return `EMAIL - ${linkData.recipient}`;
    case LINK_PANEL_LINK_TYPES.PHONE_LINK:
      return `PHONE - ${linkData.phoneNumber}`;
    case LINK_PANEL_LINK_TYPES.DOCUMENT_LINK:
      return `DOCUMENT - ${linkData.name}`;
    default:
      return 'Add a Link';
  }
};

export const convertLinkPanelToOrganizeMediaLink = (
  linkData: OrganizeMediaLinkPanelData,
): OrganizeMediaItemLink => {
  const type = 'wix';
  const text = defaultLinkText({ type: 'wix', data: linkData });
  const value = defaultLinkText({ type: 'wix', data: linkData });
  return {
    data: linkData,
    text,
    type,
    value,
  };
};

export const getOrganizeMediaLinkType = (linkType: string): string => {
  switch (linkType) {
    case WIX_LINK_DATA_LINK_TYPE.PAGE:
      return LINK_PANEL_LINK_TYPES.PAGE_LINK;
    case WIX_LINK_DATA_LINK_TYPE.EXTERNAL:
      return LINK_PANEL_LINK_TYPES.EMAIL_LINK;
    case WIX_LINK_DATA_LINK_TYPE.ANCHOR:
      return LINK_PANEL_LINK_TYPES.ANCHOR_LINK;
    case WIX_LINK_DATA_LINK_TYPE.EMAIL:
      return LINK_PANEL_LINK_TYPES.EMAIL_LINK;
    case WIX_LINK_DATA_LINK_TYPE.PHONE:
      return LINK_PANEL_LINK_TYPES.PHONE_LINK;
    case WIX_LINK_DATA_LINK_TYPE.DOCUMENT:
      return LINK_PANEL_LINK_TYPES.DOCUMENT_LINK;
    case WIX_LINK_DATA_LINK_TYPE.DYNAMIC_PAGE:
      return LINK_PANEL_LINK_TYPES.DYNAMIC_PAGE_LINK;
    case WIX_LINK_DATA_LINK_TYPE.TPA_PAGE:
      return LINK_PANEL_LINK_TYPES.TPA_PAGE_LINK;
    default:
      return '';
  }
};
export const getDraftItemLinkFieldOptions = (
  linkData: OrganizeMediaLinkPanelData,
): {
  type: string;
  isInternal: boolean;
  wixLinkData: any;
  url?: string;
} | null => {
  const { type, ...wixLinkData } = linkData;
  switch (type) {
    case LINK_PANEL_LINK_TYPES.PHONE_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.PHONE,
        isInternal: false,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.EMAIL_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.EMAIL,
        isInternal: false,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.DOCUMENT_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.DOCUMENT,
        isInternal: false,
        url: `https://docs.wixstatic.com/${linkData.docId}`,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.PAGE_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.PAGE,
        isInternal: true,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.EXTERNAL_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.EXTERNAL,
        isInternal: false,
        url: linkData.url,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.ANCHOR_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.ANCHOR,
        isInternal: true,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.DYNAMIC_PAGE_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.DYNAMIC_PAGE,
        isInternal: true,
        wixLinkData,
      };

    case LINK_PANEL_LINK_TYPES.TPA_PAGE_LINK:
      return {
        type: WIX_LINK_DATA_LINK_TYPE.TPA_PAGE,
        isInternal: true,
        wixLinkData,
      };

    default:
      return null;
  }
};
